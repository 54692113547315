import populartest01 from "../Images/test-image-01.avif";
import womenhealth from "../Images/womanhealthpackage.jpg";
import menhealth from "../Images/MaleHealthPackage.jpg";
import GenaralHealthPackage from "../Images/GenaralHealthPackage.jpg";
import FemaleAdvancePackage from "../Images/FemaleAdvancePackage.jpg";
import maleadvancepackage from "../Images/maleadvancepackage.jpg";
import FemaleSeniorCitizen from "../Images/FemaleSeniorCitizen.jpg";
import seniorcitizen from "../Images/seniorcitizen.jpg";

export const healthPackagesArray = [
  {
    id: "01",
    productName: "AAROGYAM A (41 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "LIPID (Includes 10 Tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 Tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 Tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 7 Tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 Tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 5 Tests)",
        tests: [
          "APOLIPOPROTEIN - B (APO-B)",
          "APO B / APO A1 RATIO(APO B/A1)",
          "LIPOPROTEIN (a) [Lp(a)]",
          "HIGH SENSITIVITY C-REACTIVE PROTEIN (HS-CRP)",
          "APOLIPOPROTEIN - A1 (APO-A1)",
        ],
      },
    ],
    desc: "Tests Includes Lipid, Thyroid, Liver, Renal, Iron Deficiency, Cardiac Risk Markers",
    imgUrl: populartest01,
    category: "Basic",
    price: 1399.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM A",
    overlayDetails: [
      "Lipid (10)",
      "Thyroid (24)",
      "Liver (2)",
      "Renal (4)",
      "Iron Deficiency (10)",
      "Cardiac Risk Markers (41)",
    ],
    avgRating: 4.5,
  },

  {
    id: "02",
    productName: "AAROGYAM B (71 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "LDL CHOLESTEROL - DIRECT",
          "LDL / HDL RATIO",
          "NON-HDL CHOLESTEROL",
          "VLDL CHOLESTEROL RATIO",
          "TRIG / HDL RATIO",
          "TC / HDL CHOLESTEROL RATIO",
          "HDL / LDL RATIO",
          "HDL CHOLESTEROL - DIRECT",
          "TRIGLYCERIDES",
          "TOTAL CHOLESTEROL",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "NEUTROPHILS-ABSOLUTE COUNT",
          "RED CELL DISTRIBUTION-WIDTH - SD(RDW-SD)",
          "MONOCYTES-ABSOLUTE COUNT",
          "TOTAL RBC",
          "MEAN CORP.HEMO.CONC(MCHC)",
          "BASOPHILS",
          "HEMATOCRIT(PCV)",
          "PLATELET COUNT",
          "EOSINOPHILS",
          "RED CELL DISTRIBUTION WIDTH (RDW-CV)",
          "MEAN CORPUSCULAR HEMOGLOBIN(MCH)",
          "IMMATURE GRANULOCYTE PERCENTAGE(IG%)",
          "LYMPHOCYTE",
          "MEAN CORPUSCULAR VOLUME(MCV)",
          "PLATELET DISTRIBUTION WIDTH(PDW)",
          "NUCLEATED RED BLOOD CELLS",
          "PLATELET TO LARGE CELL RATIO(PLCR)",
          "HEMOGLOBIN",
          "MONOCYTES",
          "BASOPHILS-ABSOLUTE COUNT",
          "IMMATURE GRANULOCYTES(IG)",
          "LYMPHOCYTES-ABSOLUTE COUNT",
          "NUCLEATED RED BLOOD CELLS %",
          "PLATELETCRIT(PCT)",
          "MEAN PLATELET VOLUME(MPV)",
          "TOTAL LEUCOCYTES COUNT (WBC)",
          "NEUTROPHILS",
          "EOSINOPHILS-ABSOLUTE COUNT",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "TOTAL THYROXINE (T4)",
          "TSH - ULTRASENSITIVE",
          "TOTAL TRIIODOTHYRONINE (T3)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 2 tests)",
        tests: ["HbA1c", "AVERAGE BLOOD GLUCOSE (ABG)"],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "SERUM GLOBULIN",
          "GAMMA GLUTAMYL TRANSFERASE(GGT)",
          "BILIRUBIN-TOTAL",
          "ALANINE TRANSAMINASE (SGPT)",
          "BILIRUBIN-DIRECT",
          "ALKALINE PHOSPHATESE",
          "PROTEIN-TOTAL",
          "SERUM ALB/GLOBULIN RATIO",
          "SGOT/SGPT RATIO",
          "BILIRUBIN (INDIRECT)",
          "ALBUMIN-SERUM",
          "ASPARTATE AMINOTRANSFERASE (SGOT)",
        ],
      },
      {
        categoryName: "RENAL (Includes 7 tests)",
        tests: ["UREA / SR.CREATININE RATIO","CALCIUM","BUN / Sr.CREATININE RATIO","URIC "],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 tests)",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 5 tests)",
        tests: ["03", "04", "05"],
      },
    ],
    desc: "Tests Includes Lipid, Complete Hemogram, Thyroid, Diabetes, Liver, Renal, Iron Deficiency, Cardiac Risk Markers, ",
    imgUrl: populartest01,
    category: "Basic",
    price: 1699.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM B",
    overlayDetails: [
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (24)",
      "Diabetics (2)",
      "Iron (4)",
      "Lipid Profile (10)",
    ],
    avgRating: 4.5,
  },

  {
    id: "03",
    productName: "AAROGYAM C (74 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "LIPID ",
        tests: ["01", "02"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "THYROID",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "DIABETES",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "LIVER",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "RENAL",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "IRON DEFICIENCY",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "CARDIAC RISK MARKERS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "VITAMINS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "HORMONE",
        tests: ["03", "04", "05"],
      },
    ],
    desc: "Tests Includes Lipid, Complete Hemogram, Thyroid, Diabetes, Liver, Renal, Iron Deficiency, Cardiac Risk Markers, ",
    imgUrl: populartest01,
    category: "Basic",
    price: 2499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM C",
    overlayDetails: [],
    avgRating: 4.5,
  },

  {
    id: "04",
    productName: "AAROGYAM 1.1 (63 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "LIPID ",
        tests: ["01", "02"],
      },
      {
        categoryName: "TOXIC ELEMENTS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "THYROID",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "LIVER",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "RENAL",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "IRON DEFICIENCY",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "CARDIAC RISK MARKERS",
        tests: ["03", "04", "05"],
      },
    ],
    desc: "Tests Includes Lipid, Toxic Elements, Thyroid, Liver, Renal, Iron Deficiency, Cardiac Risk Markers, ",
    imgUrl: populartest01,
    category: "Basic",
    price: 1499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM 1.1",
    overlayDetails: [
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (24)",
      "Diabetics (2)",
      "Iron (4)",
      "Lipid Profile (10)",
    ],
    avgRating: 4.5,
  },
  {
    id: "05",
    productName: "AAROGYAM 1.2 (93 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "LIPID ",
        tests: ["01", "02"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "TOXIC ELEMENTS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "THYROID",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "DIABETES",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "LIVER",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "RENAL",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "IRON DEFICIENCY",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "CARDIAC RISK MARKERS",
        tests: ["03", "04", "05"],
      },
    ],
    desc: "Tests Includes Lipid, Toxic Elements, Thyroid, Liver, Renal, Iron Deficiency, Cardiac Risk Markers, ",
    imgUrl: populartest01,
    category: "Basic",
    price: 1999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM 1.2",
    overlayDetails: [
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (24)",
      "Diabetics (2)",
      "Iron (4)",
      "Lipid Profile (10)",
    ],
    avgRating: 4.5,
  },

  {
    id: "06",
    productName: "AAROGYAM 1.3 (100 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "LIPID ",
        tests: ["01", "02"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "TOXIC ELEMENTS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "THYROID",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "DIABETES",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "LIVER",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "RENAL",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "IRON DEFICIENCY",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "CARDIAC RISK MARKERS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "VITAMINS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "ELEMENTS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "HORMONE",
        tests: ["03", "04", "05"],
      },
    ],
    desc: "Tests Includes Lipid, Complete Hemogram, Toxic Elements, Thyroid, Diabetes, Liver, Renal, Iron Deficiency, Cardiac Risk Markers, Vitamins, Elements, Hormone ",
    imgUrl: populartest01,
    category: "Basic",
    price: 2999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM 1.3",
    overlayDetails: [
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (24)",
      "Diabetics (2)",
      "Iron (4)",
      "Lipid Profile (10)",
    ],
    avgRating: 4.5,
  },

  {
    id: "07",
    productName: "AAROGYAM 1.4 (107 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "DIABETES ",
        tests: ["01", "02"],
      },
      {
        categoryName: "LIPID",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "PANCREATIC",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "TOXIC ELEMENTS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "THYROID",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "LIVER",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "RENAL",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "IRON DEFICIENCY",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "CARDIAC RISK MARKERS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "VITAMINS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "ELECTROLYTES",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "ELEMENTS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "HORMONE",
        tests: ["03", "04", "05"],
      },
    ],
    desc: "Tests Includes Diabetes, Lipid, Pancreatic, Complete Hemogram, Toxic Elements, Thyroid, Liver, Renal, Iron Deficiency, Cardiac Risk Markers, Vitamins, Electrolytes, Elements, Hormone ",
    imgUrl: populartest01,
    category: "Basic",
    price: 3499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM 1.4",
    overlayDetails: [
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (24)",
      "Diabetics (2)",
      "Iron (4)",
      "Lipid Profile (10)",
    ],
    avgRating: 4.5,
  },

  {
    id: "08",
    productName: "AAROGYAM 1.5 (110 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "DIABETES ",
        tests: ["01", "02"],
      },
      {
        categoryName: "LIPID",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "PANCREATIC",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "TOXIC ELEMENTS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "THYROID",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "LIVER",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "RENAL",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "IRON DEFICIENCY",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "CARDIAC RISK MARKERS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "VITAMINS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "ELECTROLYTES",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "ELEMENTS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "HORMONE",
        tests: ["03", "04", "05"],
      },
    ],
    desc: "Tests Includes Diabetes, Lipid, Pancreatic, Complete Hemogram, Toxic Elements, Thyroid, Liver, Renal, Iron Deficiency, Cardiac Risk Markers, Vitamins, Electrolytes, Elements, Hormone ",
    imgUrl: populartest01,
    category: "Basic",
    price: 3499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM 1.5",
    overlayDetails: [
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (24)",
      "Diabetics (2)",
      "Iron (4)",
      "Lipid Profile (10)",
    ],
    avgRating: 4.5,
  },
  {
    id: "09",
    productName: "AAROGYAM 1.6 (112 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "DIABETES ",
        tests: ["01", "02"],
      },
      {
        categoryName: "LIPID",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "PANCREATIC",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "TOXIC ELEMENTS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "THYROID",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "LIVER",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "RENAL",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "IRON DEFICIENCY",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "CARDIAC RISK MARKERS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "VITAMINS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "ELECTROLYTES",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "ELEMENTS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "HORMONE",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "ARTHRITIS",
        tests: ["03", "04", "05"],
      },
    ],
    desc: "Tests Includes Diabetes, Lipid, Pancreatic, Complete Hemogram, Toxic Elements, Thyroid, Liver, Renal, Iron Deficiency, Cardiac Risk Markers, Vitamins, Electrolytes, Elements, Hormone ",
    imgUrl: populartest01,
    category: "Basic",
    price: 4499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM 1.6",
    overlayDetails: [
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (24)",
      "Diabetics (2)",
      "Iron (4)",
      "Lipid Profile (10)",
    ],
    avgRating: 4.5,
  },
  {
    id: "10",
    productName: "AAROGYAM 1.7 (113 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "DIABETES ",
        tests: ["01", "02"],
      },
      {
        categoryName: "LIPID",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "PANCREATIC",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "TOXIC ELEMENTS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "THYROID",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "LIVER",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "RENAL",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "IRON DEFICIENCY",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "CARDIAC RISK MARKERS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "VITAMINS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "ELECTROLYTES",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "ELEMENTS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "HORMONE",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "ARTHRITIS",
        tests: ["03", "04", "05"],
      },
    ],
    desc: "Tests Includes Diabetes, Lipid, Pancreatic, Complete Hemogram, Toxic Elements, Thyroid, Liver, Renal, Iron Deficiency, Cardiac Risk Markers, Vitamins, Electrolytes, Elements, Hormone, Arthritis ",
    imgUrl: populartest01,
    category: "Basic",
    price: 4999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM 1.7",
    overlayDetails: [
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (24)",
      "Diabetics (2)",
      "Iron (4)",
      "Lipid Profile (10)",
    ],
    avgRating: 4.5,
  },
  {
    id: "11",
    productName: "AAROGYAM 1.8 (121 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CARDIAC RISK MARKERS ",
        tests: ["01", "02"],
      },
      {
        categoryName: "COMPLETE HEMOGRAM",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "DIABETES",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "ELECTROLYTES",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "ELEMENTS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "HORMONE",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "IRON DEFICIENCY",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "LIPID",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "LIVER",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "PANCREATIC",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "RENAL",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "THYROID",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "TOXIC ELEMENTS",
        tests: ["03", "04", "05"],
      },
      {
        categoryName: "VITAMINS",
        tests: ["03", "04", "05"],
      },
    ],
    desc: "Tests Includes CARDIAC RISK MARKERS, COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, ELEMENTS, HORMONE, IRON DEFICIENCY, LIPID, LIVER, PANCREATIC, RENAL, THYROID, TOXIC ELEMENTS, VITAMINS ",
    imgUrl: populartest01,
    category: "Basic",
    price: 5999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "AAROGYAM 1.8",
    overlayDetails: [
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (24)",
      "Diabetics (2)",
      "Iron (4)",
      "Lipid Profile (10)",
    ],
    avgRating: 4.5,
  },

  {
    id: "12",
    productName: "Aarogyam Winter Basic With Utsh (68 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests) ",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "ELECTROLYTES (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 1 tests)",
        tests: ["Tsh - ultrasensitive"],
      },
      {
        categoryName: "VITAMIN (Includes 1 tests)",
        tests: ["Vitamin b-12"],
      },
      {
        categoryName: "VITAMINS (Includes 1 tests)",
        tests: ["25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, IRON DEFICIENCY, LIPID, LIVER, RENAL, THYROID, VITAMIN, VITAMINS ",
    imgUrl: populartest01,
    category: "Basic",
    price: 1199.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam Winter Basic With Utsh",
    overlayDetails: [
      "COMPLETE HEMOGRAM (28)",
      "DIABETES (2)",
      "ELECTROLYTES (2)",
      "IRON DEFICIENCY (4)",
      "LIPID (10)",
      "LIVER (12)",
      "RENAL (7)",
      "THYROID (1)",
      "VITAMIN (1)",
      "VITAMINS (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "13",
    productName: "Aarogyam Winter Advanced With Utsh (79 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 5 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 4 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Blood ketone (d3hb)",
          "Fructosamine",
          "Hba1c",
        ],
      },
      {
        categoryName: "ELEMENTS (Includes 2 tests)",
        tests: ["Serum copper", "Serum zinc"],
      },
      {
        categoryName: "HORMONE (Includes 1 tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 8 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Cystatin c",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "VITAMINS (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes CARDIAC RISK MARKERS, COMPLETE HEMOGRAM, DIABETES, ELEMENTS, HORMONE, IRON DEFICIENCY, LIPID, LIVER, PANCREATIC, RENAL, THYROID, VITAMINS ",
    imgUrl: populartest01,
    category: "Basic",
    price: 1699.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam Winter Advanced With Utsh",
    overlayDetails: [
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (28)",
      "Diabetics (4)",
      "ELEMENTS (2)",
      "HORMONE (1)",
      "IRON DEFICIENCY (4)",
      "LIPID (10)",
      "PANCREATIC (12)",
      "RENAL (8)",
      "THYROID (3)",
      "VITAMINS (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "14",
    productName: "Aarogyam Tax Saver - Basic With Utsh (83 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 6 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "Homocysteine",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 4 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Blood ketone (d3hb)",
          "Fructosamine",
          "Hba1c",
        ],
      },
      {
        categoryName: "ELECTROLYTES (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "ELEMENTS (Includes 2 tests)",
        tests: ["Serum copper", "Serum zinc"],
      },
      {
        categoryName: "HORMONE (Includes 1 tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "PANCREATIC (Includes 2 tests)",
        tests: ["Amylase", "Lipase"],
      },
      {
        categoryName: "RENAL (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "VITAMIN (Includes 2 tests)",
        tests: ["Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes CARDIAC RISK MARKERS, COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, ELEMENTS, HORMONE, IRON DEFICIENCY, LIPID, LIVER, PANCREATIC, RENAL, THYROID, VITAMIN ",
    imgUrl: populartest01,
    category: "Basic",
    price: 3999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam Tax Saver - Basic With Utsh",
    overlayDetails: [
      "Cardiac Risk Markers (6)",
      "Complete Hemogram (28)",
      "Diabetics (4)",
      "ELECTROLYTES (2)",
      "ELEMENTS (2)",
      "HORMONE (1)",
      "IRON DEFICIENCY (4)",
      "LIPID (10)",
      "LIVER (12)",
      "PANCREATIC (2)",
      "RENAL (7)",
      "THYROID (3)",
      "VITAMIN (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "15",
    productName: "Aarogyam Tax Saver - Advanced With Utsh (136 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "ARTHRITIS (Includes 2 tests)",
        tests: ["Anti ccp (accp)", "Anti nuclear antibodies (ana)"],
      },
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 7 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "Homocysteine",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
          "Lp-pla2",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 7 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Blood ketone (d3hb)",
          "Fasting blood sugar(glucose)",
          "Fructosamine",
          "Hba1c",
          "Insulin - fasting",
          "Urinary microalbumin",
        ],
      },
      {
        categoryName: "ELECTROLYTES (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "ELEMENTS (Includes 2 tests)",
        tests: ["Serum copper", "Serum zinc"],
      },
      {
        categoryName: "HORMONE (Includes 1 tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 5 tests)",
        tests: [
          "% transferrin saturation",
          "Ferritin",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "METABOLIC (Includes 1 tests)",
        tests: ["Magnesium"],
      },
      {
        categoryName: "PANCREATIC (Includes 2 tests)",
        tests: ["Amylase", "Lipase"],
      },
      {
        categoryName: "RENAL (Includes 10 tests)L",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Cystatin c",
          "Creatinine - serum",
          "Uri. albumin/creatinine ratio (ua/c)",
          "Creatinine - urine",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "URINOGRAM (Includes 10 tests)",
        tests: [
          "Specific gravity",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Urinary glucose",
          "Urine ketone",
          "Urinary leucocytes (pus cells)",
          "Nitrite",
          "Ph",
          "Urinary protein",
        ],
      },
      {
        categoryName: "VITAMIN (Includes 12 tests)",
        tests: [
          "Vitamin a",
          "Vitamin b-12",
          "Vitamin b1/thiamin",
          "Vitamin b2/riboflavin",
          "Vitamin b3/nicotinic acid",
          "Vitamin b5/pantothenic",
          "Vitamin b6/pyridoxal - 5 - phosphate",
          "Vitamin b7/biotin",
          "Vitamin b9/folic acid",
          "25-oh vitamin d (total)",
          "Vitamin e",
          "Vitamin k",
        ],
      },
    ],
    desc: "Tests Includes ARTHRITIS, CARDIAC RISK MARKERS, COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, ELEMENTS, HORMONE, IRON DEFICIENCY, LIPID, LIVER, METABOLIC, PANCREATIC, RENAL, THYROID, TOXIC ELEMENTS, URINOGRAM, VITAMIN ",
    imgUrl: populartest01,
    category: "Basic",
    price: 6999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam Tax Saver - Advanced With Utsh",
    overlayDetails: [
      "ARTHRITIS (5)",
      "CARDIAC RISK MARKERS (24)",
      "COMPLETE HEMOGRAM (2)",
      "DIABETES (4)",
      "ELECTROLYTES (10)",
      "ELEMENTS (2)",
      "HORMONE (1)",
      "IRON DEFICIENCY (5)",
      "LIPID (10)",
      "LIVER (12)",
      "METABOLIC (1)",
      "PANCREATIC (2)",
      "RENAL (10)",
      "THYROID (3)",
      "TOXIC ELEMENTS (22)",
      "URINOGRAM (10)",
      "VITAMIN (12)",
    ],
    avgRating: 4.5,
  },

  {
    id: "16",
    productName: "Aarogyam Basic 1 With Utsh (84 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "COMPLETE URINE ANALYSIS (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },
      {
        categoryName: "DIABETES (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 1 tests)",
        tests: ["Tsh - ultrasensitive"],
      },
    ],
    desc: "Tests Includes COMPLETE HEMOGRAM, COMPLETE URINE ANALYSIS, DIABETES, LIPID, LIVER, RENAL, THYROID ",
    imgUrl: populartest01,
    category: "Basic",
    price: 950.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam Basic 1 With Utsh",
    overlayDetails: [
      "Complete Hemogram (28)",
      "COMPLETE URINE ANALYSIS (24)",
      "DIABETES (2)",
      "LIPID (10)",
      "LIVER (12)",
      "RENAL (7)",
      "THYROID (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "17",
    productName: "Aarogyam Basic 2 With Utsh (90 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "COMPLETE URINE ANALYSIS (Includes 24 tests)",
        tests: [
          "Specific gravity",
          "Appearance",
          "Bacteria",
          "Urinary bilirubin",
          "Urine blood",
          "Urobilinogen",
          "Bile pigment",
          "Bile salt",
          "Casts",
          "Colour",
          "Crystals",
          "Epithelial cells",
          "Urinary glucose",
          "Urine ketone",
          "Leucocyte esterase",
          "Urinary leucocytes (pus cells)",
          "Mucus",
          "Nitrite",
          "Parasite",
          "Ph",
          "Urinary protein",
          "Red blood cells",
          "Volume",
          "Yeast",
        ],
      },
      {
        categoryName: "DIABETES (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
    ],
    desc: "Tests Includes COMPLETE HEMOGRAM, COMPLETE URINE ANALYSIS, DIABETES, IRON DEFICIENCY, LIPID, LIVER, RENAL, THYROID ",
    imgUrl: populartest01,
    category: "Basic",
    price: 1050.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam Basic 2 With Utsh",
    overlayDetails: [
      "Complete Hemogram (28)",
      "COMPLETE URINE ANALYSIS (24)",
      "DIABETES (2)",
      "IRON DEFICIENCY (4)",
      "LIPID (10)",
      "LIVER (12)",
      "RENAL (7)",
      "THYROID (3)",
    ],
    avgRating: 4.5,
  },

  {
    id: "18",
    productName: "Aarogyam Male With Utsh (103 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CANCER MARKERS (Includes 1 tests)",
        tests: ["Prostate specific antigen (psa)"],
      },
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 5 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "ELECTROLYTES (Includes 3 tests)",
        tests: ["Chloride", "Potassium", "Sodium"],
      },
      {
        categoryName: "HORMONE (Includes 1 tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "PANCREATIC (Includes 1 tests)",
        tests: ["Amylase"],
      },
      {
        categoryName: "PANCREATIC (Includes 1 tests)",
        tests: ["Lipase"],
      },
      {
        categoryName: "RENAL (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "VITAMIN (Includes 1 tests)",
        tests: ["25-oh vitamin d (total)"],
      },
      {
        categoryName: "VITAMINS (Includes 2 tests)",
        tests: ["Folate", "Vitamin b-12"],
      },
    ],
    desc: "Tests Includes CANCER MARKERS, CARDIAC RISK MARKERS, COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, HORMONE, IRON DEFICIENCY, LIPID, LIVER, PANCREATIC, PANCREATIC, RENAL, THYROID, TOXIC ELEMENTS, VITAMIN, VITAMINS ",
    imgUrl: populartest01,
    category: "Basic",
    price: 2400.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam Male With Utsh",
    overlayDetails: [
      "CANCER MARKERS (1)",
      "CARDIAC RISK MARKERS (5)",
      "COMPLETE HEMOGRAM (28)",
      "DIABETES (2)",
      "ELECTROLYTES (3)",
      "HORMONE (1)",
      "IRON DEFICIENCY (4)",
      "LIPID (10)",
      "LIVER (12)",
      "PANCREATIC (1)",
      "PANCREATIC (1)",
      "RENAL (7)",
      "THYROID (3)",
      "TOXIC ELEMENTS (22)",
      "VITAMIN (1)",
      "VITAMINS (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "19",
    productName: "Aarogyam Female With Utsh (105 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 5 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "ELECTROLYTES (Includes 3 tests)",
        tests: ["Chloride", "Potassium", "Sodium"],
      },
      {
        categoryName: "HORMONE (Includes 1 tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "INFERTILITY (Includes 3 tests)",
        tests: [
          "Follicle stimulating hormone (fsh)",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "PANCREATIC (Includes 2 tests)",
        tests: ["Amylase", "Lipase"],
      },
      {
        categoryName: "RENAL (Includes 7 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "VITAMIN (Includes 1 tests)",
        tests: ["Vitamin b-12"],
      },
      {
        categoryName: "VITAMINS (Includes 2 tests)",
        tests: ["Folate", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes CARDIAC RISK MARKERS, COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, HORMONE, INFERTILITY, IRON DEFICIENCY, LIPID, LIVER, PANCREATIC, PANCREATIC, RENAL, THYROID, TOXIC ELEMENTS, VITAMIN, VITAMINS ",
    imgUrl: populartest01,
    category: "Basic",
    price: 2600.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Aarogyam Female With Utsh",
    overlayDetails: [
      "Cardiac Risk Markers (5)",
      "Complete Hemogram (28)",
      "Diabetics (2)",
      "ELECTROLYTES (3)",
      "HORMONE (1)",
      "INFERTILITY (3)",
      "IRON DEFICIENCY (4)",
      "LIPID (10)",
      "LIVER (12)",
      "PANCREATIC (1)",
      "PANCREATIC (2)",
      "RENAL (7)",
      "THYROID (3)",
      "TOXIC ELEMENTS (22)",
      "VITAMIN (1)",
      "VITAMINS (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "20",
    productName: "Jaanch - Infertility Profile Basic (7 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "INFERTILITY (Includes 4 tests)",
        tests: [
          "Anti mullerian hormone (amh)",
          "Follicle stimulating hormone (fsh)",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "THYROID (Includes 4 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
    ],
    desc: "Tests Includes INFERTILITY, THYROID",
    imgUrl: populartest01,
    category: "Basic",
    price: 1199.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Infertility Profile Basic",
    overlayDetails: ["INFERTILITY (4)", "THYROID (4)"],
    avgRating: 4.5,
  },

  {
    id: "21",
    productName: "Jaanch - Infertility Profile Advanced (29 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "DIABETES (Includes 3 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Fasting blood sugar(glucose)",
          "Hba1c",
        ],
      },
      {
        categoryName: "HORMONE (Includes 2 tests)",
        tests: ["Free androgen index", "Testosterone"],
      },
      {
        categoryName: "HYPERTENSION (Includes 1 tests)",
        tests: ["Aldosterone"],
      },
      {
        categoryName: "INFERTILITY (Includes 1 tests)",
        tests: [
          "Anti mullerian hormone (amh)",
          "Follicle stimulating hormone (fsh)",
          "Free testosterone",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
          "Sex hormone binding globulin (shbg)",
        ],
      },
      {
        categoryName: "STEROID (Includes 9 tests)",
        tests: [
          "Androstenedione",
          "17-hydroxyprogesterone",
          "Cortisol",
          "Corticosterone",
          "Deoxycortisol",
          "Dehydroepiandrosterone",
          "Dhea - sulphate (dheas)",
          "Estradiol",
          "Progesterone",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "VITAMINS (Includes 5 tests)",
        tests: [
          "Folate",
          "Vitamin d total",
          "Vitamin d2",
          "Vitamin d3",
          "Vitamin b-12",
        ],
      },
    ],
    desc: "Tests Includes DIABETES, HORMONE, HYPERTENSION, INFERTILITY, STEROID, THYROID, VITAMINS ",
    imgUrl: populartest01,
    category: "Basic",
    price: 1999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Infertility Profile Advanced",
    overlayDetails: [
      "DIABETES (3)",
      "HORMONE (2)",
      "HYPERTENSION (1)",
      "INFERTILITY (1)",
      "STEROID (9)",
      "THYROID (3)",
      "VITAMINS (5)",
    ],
    avgRating: 4.5,
  },

  {
    id: "22",
    productName: "Jaanch - Pcod (Mini) (50 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 6 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Fasting blood sugar(glucose)",
          "Hba1c",
          "Homa insulin resistance index",
          "Insulin - fasting",
          "Quantitative insulin sensitivity index",
        ],
      },
      {
        categoryName: "INFERTILITY (Includes 4 tests)",
        tests: [
          "Follicle stimulating hormone (fsh)",
          "Free testosterone",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "PREGNANCY (Includes 1 tests)",
        tests: ["Estradiol/oestrogen (e2)"],
      },
      {
        categoryName: "THYROID (Includes 1 tests)",
        tests: ["Tsh - ultrasensitive"],
      },
    ],
    desc: "Tests Includes COMPLETE HEMOGRAM, DIABETES, INFERTILITY, LIPID, PREGNANCY, THYROID ",
    imgUrl: populartest01,
    category: "Basic",
    price: 1999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Pcod (Mini)",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Diabetics (6)",
      "INFERTILITY (4)",
      "LIPID (10)",
      "PREGNANCY (1)",
      "THYROID (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "23",
    productName: "Jaanch - Pcod Basic (69 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 6 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Fasting blood sugar(glucose)",
          "Hba1c",
          "Homa insulin resistance index",
          "Insulin - fasting",
          "Quantitative insulin sensitivity index",
        ],
      },
      {
        categoryName: "HORMONE (Includes 1 tests)",
        tests: ["Testosterone"],
      },
      {
        categoryName: "INFERTILITY (Includes 6 tests)",
        tests: [
          "17 oh progesterone",
          "Dhea - sulphate (dheas)",
          "Follicle stimulating hormone (fsh)",
          "Free testosterone",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "PREGNANCY (Includes 1 tests)",
        tests: ["Estradiol/oestrogen (e2)"],
      },
      {
        categoryName: "RENAL (Includes 4 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Creatinine - serum",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 1 tests)",
        tests: ["Tsh - ultrasensitive"],
      },
    ],
    desc: "Tests Includes COMPLETE HEMOGRAM, DIABETES, HORMONE, INFERTILITY, LIPID, LIVER, PREGNANCY, RENAL, THYROID",
    imgUrl: populartest01,
    category: "Basic",
    price: 4999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Pcod Basic",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Diabetics (6)",
      "HORMONE (1)",
      "INFERTILITY (6)",
      "LIPID (10)",
      "LIVER (12)",
      "PREGNANCY (1)",
      "RENAL (4)",
      "THYROID (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "24",
    productName: "Jaanch - Pcod Advanced (73 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 6 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Fasting blood sugar(glucose)",
          "Hba1c",
          "Homa insulin resistance index",
          "Insulin - fasting",
          "Quantitative insulin sensitivity index",
        ],
      },
      {
        categoryName: "HORMONE (Includes 2 tests)",
        tests: ["Free androgen index", "Testosterone"],
      },
      {
        categoryName: "INFERTILITY (Includes 8 tests)",
        tests: [
          "17 oh progesterone",
          "Anti mullerian hormone (amh)",
          "Dhea - sulphate (dheas)",
          "Follicle stimulating hormone (fsh)",
          "Free testosterone",
          "Luteinising hormone (lh)",
          "Prolactin (prl)",
          "Sex hormone binding globulin (shbg)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "PREGNANCY (Includes 1 tests)",
        tests: ["Estradiol/oestrogen (e2)"],
      },
      {
        categoryName: "RENAL (Includes 4 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Creatinine - serum",
          "Uric acid",
        ],
      },
      {
        categoryName: "STEROID HORMONE (Includes 1 tests)",
        tests: ["Dihydrotestosterone (dht)"],
      },
      {
        categoryName: "THYROID (Includes 1 tests)",
        tests: ["Tsh - ultrasensitive"],
      },
    ],
    desc: "Tests Includes COMPLETE HEMOGRAM, DIABETES, HORMONE, INFERTILITY, LIPID, LIVER, PREGNANCY, RENAL, STEROID HORMONE, THYROID ",
    imgUrl: populartest01,
    category: "Basic",
    price: 5999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Pcod Advanced",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Diabetics (6)",
      "HORMONE (2)",
      "INFERTILITY (8)",
      "LIPID (10)",
      "LIVER (12)",
      "PREGNANCY (1)",
      "RENAL (4)",
      "STEROID HORMONE (1)",
      "THYROID (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "25",
    productName: "Jaanch - Diabetic Profile Basic (73 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 5 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Blood ketone (d3hb)",
          "Fructosamine",
          "Hba1c",
          "Urinary microalbumin",
        ],
      },
      {
        categoryName: "ELECTROLYTES (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "RENAL (Includes 9 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Uri. albumin/creatinine ratio (ua/c)",
          "Creatinine - urine",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
    ],
    desc: "Tests Includes COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, IRON DEFICIENCY, LIPID, LIVER, RENAL, THYROID",
    imgUrl: populartest01,
    category: "Basic",
    price: 2499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Diabetic Profile Basic",
    overlayDetails: [
      "Complete Hemogram (28)",
      "Diabetics (5)",
      "ELECTROLYTES (2)",
      "IRON DEFICIENCY (4)",
      "LIPID (10)",
      "LIVER (12)",
      "RENAL (9)",
      "THYROID (3)",
    ],
    avgRating: 4.5,
  },

  {
    id: "26",
    productName: "Jaanch - Diabetic Profile Advanced (109 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 7 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "Homocysteine",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
          "Lp-pla2",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 7 tests)",
        tests: [
          "Average blood glucose (abg)",
          "Blood ketone (d3hb)",
          "C-peptide",
          "Fructosamine",
          "Hba1c",
          "Insulin - fasting",
          "Urinary microalbumin",
        ],
      },
      {
        categoryName: "ELECTROLYTES (Includes 2 tests)",
        tests: ["Chloride", "Sodium"],
      },
      {
        categoryName: "IRON DEFICIENCY (Includes 4 tests)",
        tests: [
          "% transferrin saturation",
          "Iron",
          "Total iron binding capacity (tibc)",
          "Unsat.iron-binding capacity(uibc)",
        ],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
      {
        categoryName: "LIVER (Includes 12 tests)",
        tests: [
          "Serum alb/globulin ratio",
          "Alkaline phosphatase",
          "Bilirubin -direct",
          "Bilirubin (indirect)",
          "Bilirubin - total",
          "Gamma glutamyl transferase (ggt)",
          "Sgot / sgpt ratio",
          "Protein - total",
          "Albumin - serum",
          "Serum globulin",
          "Aspartate aminotransferase (sgot )",
          "Alanine transaminase (sgpt)",
        ],
      },
      {
        categoryName: "PANCREATIC (Includes 2 tests)",
        tests: ["Amylase", "Lipase"],
      },
      {
        categoryName: "RENAL (Includes 9 tests)",
        tests: [
          "Bun / sr.creatinine ratio",
          "Blood urea nitrogen (bun)",
          "Calcium",
          "Creatinine - serum",
          "Uri. albumin/creatinine ratio (ua/c)",
          "Creatinine - urine",
          "Urea / sr.creatinine ratio",
          "Urea (calculated)",
          "Uric acid",
        ],
      },
      {
        categoryName: "THYROID (Includes 3 tests)",
        tests: [
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
      {
        categoryName: "TOXIC ELEMENTS (Includes 22 tests)",
        tests: [
          "Silver",
          "Aluminium",
          "Arsenic",
          "Barium",
          "Beryllium",
          "Bismuth",
          "Cadmium",
          "Cobalt",
          "Chromium",
          "Caesium",
          "Mercury",
          "Manganese",
          "Molybdenum",
          "Nickel",
          "Lead",
          "Antimony",
          "Selenium",
          "Tin",
          "Strontium",
          "Thallium",
          "Uranium",
          "Vanadium",
        ],
      },
      {
        categoryName: "VITAMINS (Includes 3 tests)",
        tests: ["Folate", "Vitamin b-12", "25-oh vitamin d (total)"],
      },
    ],
    desc: "Tests Includes CARDIAC RISK MARKERS, COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, IRON DEFICIENCY, LIPID, LIVER, PANCREATIC, RENAL, THYROID, TOXIC ELEMENTS, VITAMINS",
    imgUrl: populartest01,
    category: "Basic",
    price: 4999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Diabetic Profile Advanced",
    overlayDetails: [
      "Cardiac Risk Markers (7)",
      "Complete Hemogram (28)",
      "Diabetics (7)",
      "ELECTROLYTES (2)",
      "IRON DEFICIENCY (4)",
      "LIPID (10)",
      "LIVER (12)",
      "PANCREATIC (2)",
      "RENAL (9)",
      "THYROID (3)",
      "TOXIC ELEMENTS (22)",
      "VITAMINS (3)",
    ],
    avgRating: 4.5,
  },

  {
    id: "27",
    productName: "Jaanch - Tumour Panel (Male) (7 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CANCER MARKERS (Includes 5 tests)",
        tests: [
          "Alpha feto protein (afp)",
          "Ca 19.9",
          "Carcino embryonic antigen (cea)",
          "Free psa",
          "Prostate specific antigen (psa)",
        ],
      },
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 1 tests)",
        tests: ["Percent free psa", ""],
      },
      {
        categoryName: "PREGNANCY (Includes 1 tests)",
        tests: ["Beta hcg"],
      },
    ],
    desc: "Tests Includes CANCER MARKERS, CARDIAC RISK MARKERS, PREGNANCY",
    imgUrl: populartest01,
    category: "Basic",
    price: 1999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Tumour Panel (Male)",
    overlayDetails: [
      "CANCER MARKERS (5)",
      "CARDIAC RISK MARKERS (1)",
      "PREGNANCY (1)",
    ],
    avgRating: 4.5,
  },

  {
    id: "28",
    productName: "Jaanch - Tumour Panel (Female) (6 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CANCER MARKERS (Includes 4 tests)",
        tests: [
          "Ca-125",
          "Ca 15.3",
          "Ca 19.9",
          "Carcino embryonic antigen (cea)",
        ],
      },
      {
        categoryName: "OTHERS (Includes 1 tests)",
        tests: ["Alpha feto protein (afp)"],
      },
      {
        categoryName: "PREGNANCY (Includes 1 tests)",
        tests: ["Beta hcg"],
      },
    ],
    desc: "Tests Includes CANCER MARKERS, OTHERS, PREGNANCY",
    imgUrl: populartest01,
    category: "Basic",
    price: 1999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Tumour Panel (Female)",
    overlayDetails: ["CANCER MARKERS (4)", "OTHERS (1)", "PREGNANCY (1)"],
    avgRating: 4.5,
  },

  {
    id: "29",
    productName: "Jaanch Thyroid Profile - Basic (5 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "THYROID (Includes 5 tests)",
        tests: [
          "Free triiodothyronine (ft3)",
          "Free thyroxine (ft4)",
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
    ],
    desc: "Tests Includes THYROID",
    imgUrl: populartest01,
    category: "Basic",
    price: 499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch Thyroid Profile - Basic",
    overlayDetails: ["THYROID (5)"],
    avgRating: 4.5,
  },

  {
    id: "30",
    productName: "Jaanch Thyroid Profile - Advanced (8 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "AUTOIMMUNE DISORDERS (Includes 1 tests)",
        tests: ["Tsh receptor antibodies", ""],
      },
      {
        categoryName: "AUTOIMMUNITY (Includes 1 tests)",
        tests: ["Anti thyroglobulin antibody (atg)"],
      },
      {
        categoryName: "THYROID (Includes 6 tests)",
        tests: [
          "Anti microsomal antibody (ama)",
          "Free triiodothyronine (ft3)",
          "Free thyroxine (ft4)",
          "Total triiodothyronine (t3)",
          "Total thyroxine (t4)",
          "Tsh - ultrasensitive",
        ],
      },
    ],
    desc: "Tests Includes AUTOIMMUNE DISORDERS, AUTOIMMUNITY, THYROID",
    imgUrl: populartest01,
    category: "Basic",
    price: 2499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch Thyroid Profile - Advanced",
    overlayDetails: [
      "AUTOIMMUNE DISORDERS (1)",
      "AUTOIMMUNITY (1)",
      "THYROID (6)",
    ],
    avgRating: 4.5,
  },

  {
    id: "31",
    productName: "Jaanch Healthy Heart Advanced New (53 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CARDIAC (Includes 2 tests)",
        tests: [
          "Creatinine phosphokinase",
          "Creatinine phosphokinase (muscle/brain)",
        ],
      },
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 7 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "Homocysteine",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
          "Lp-pla2",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "ELECTROLYTES (Includes 3 tests)",
        tests: ["Chloride", "Potassium", "Sodium"],
      },
      {
        categoryName: "HEMATOLOGY (Includes 1 tests)",
        tests: ["Troponin i heart attack risk"],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
    ],
    desc: "Tests Includes CARDIAC, CARDIAC RISK MARKERS, COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, HEMATOLOGY, LIPID",
    imgUrl: populartest01,
    category: "Basic",
    price: 2999.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch Healthy Heart Advanced New",
    overlayDetails: [
      "CARDIAC (2)",
      "CARDIAC RISK MARKERS (7)",
      "COMPLETE HEMOGRAM (28)",
      "DIABETES (2)",
      "ELECTROLYTES (3)",
      "HEMATOLOGY (1)",
      "LIPID (10)",
    ],
    avgRating: 4.5,
  },

  {
    id: "32",
    productName: "Jaanch Healthy Heart Basic New (48 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "CARDIAC RISK MARKERS (Includes 5 tests)",
        tests: [
          "Apo b / apo a1 ratio (apo b/a1)",
          "Apolipoprotein - a1 (apo-a1)",
          "Apolipoprotein - b (apo-b)",
          "High sensitivity c-reactive protein (hs-crp)",
          "Lipoprotein (a) [lp(a)]",
        ],
      },
      {
        categoryName: "COMPLETE HEMOGRAM (Includes 28 tests)",
        tests: [
          "Basophils - absolute count",
          "Eosinophils - absolute count",
          "Lymphocytes - absolute count",
          "Monocytes - absolute count",
          "Neutrophils - absolute count",
          "Basophils",
          "Eosinophils",
          "Hemoglobin",
          "Immature granulocytes(ig)",
          "Immature granulocyte percentage(ig%)",
          "Total leucocytes count (wbc)",
          "Lymphocyte",
          "Mean corpuscular hemoglobin(mch)",
          "Mean corp.hemo.conc(mchc)",
          "Mean corpuscular volume(mcv)",
          "Monocytes",
          "Mean platelet volume(mpv)",
          "Neutrophils",
          "Nucleated red blood cells",
          "Nucleated red blood cells %",
          "Plateletcrit(pct)",
          "Hematocrit(pcv)",
          "Platelet distribution width(pdw)",
          "Platelet to large cell ratio(plcr)",
          "Platelet count",
          "Total rbc",
          "Red cell distribution width (rdw-cv)",
          "Red cell distribution width - sd(rdw-sd)",
        ],
      },
      {
        categoryName: "DIABETES (Includes 2 tests)",
        tests: ["Average blood glucose (abg)", "Hba1c"],
      },
      {
        categoryName: "ELECTROLYTES (Includes 3 tests)",
        tests: ["Chloride", "Potassium", "Sodium"],
      },
      {
        categoryName: "LIPID (Includes 10 tests)",
        tests: [
          "Total cholesterol",
          "Hdl cholesterol - direct",
          "Hdl / ldl ratio",
          "Ldl cholesterol - direct",
          "Ldl / hdl ratio",
          "Non-hdl cholesterol",
          "Tc/ hdl cholesterol ratio",
          "Trig / hdl ratio",
          "Triglycerides",
          "Vldl cholesterol",
        ],
      },
    ],
    desc: "Tests Includes CARDIAC RISK MARKERS, COMPLETE HEMOGRAM, DIABETES, ELECTROLYTES, LIPID",
    imgUrl: populartest01,
    category: "Basic",
    price: 1499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch Healthy Heart Basic New",
    overlayDetails: [
      "CARDIAC RISK MARKERS (5)",
      "COMPLETE HEMOGRAM (28)",
      "DIABETES (2)",
      "ELECTROLYTES (3)",
      "LIPID (10)",
    ],
    avgRating: 4.5,
  },

  {
    id: "33",
    productName: "Jaanch - Std Profile Basic (12 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "HEPATITIS PANEL (Includes 1 tests)",
        tests: ["Anti hepatitis c virus (anti hcv) - total"],
      },
      {
        categoryName: "INFECTIOUS DISEASES (Includes 8 tests)",
        tests: [
          "Anti chlamydia antibody igg",
          "Anti chlamydia antibody igm",
          "Hiv i and ii",
          "Herpes simplex virus ii (hsv)-igg",
          "Hepatitis b surface antigen (hbsag)",
          "Treponema pallidum antibody (tpab)",
          "Treponema pallidum haemagglutination",
          "Vdrl (rpr) for syphilis",
        ],
      },
      {
        categoryName: "OTHERS (Includes 1 tests)",
        tests: ["Herpes simplex virus ii (hsv)-igm"],
      },
      {
        categoryName: "PREGNANCY (Includes 2 tests)",
        tests: [
          "Herpes simplex virus i (hsv)-igg",
          "Herpes simplex virus i (hsv)-igm",
        ],
      },
    ],
    desc: "Tests Includes HEPATITIS PANEL, INFECTIOUS DISEASES, OTHERS, PREGNANCY",
    imgUrl: populartest01,
    category: "Basic",
    price: 2499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Full body checkup is an essential health test that can detect hidden diseases. Reliable pathology labs like Fortune Blood Test offer a perfect solution to this challenge by providing comprehensive health packages with blood test at home services. These preventive health checkups include specific tests recommended by certified doctors and expert pathologists. It is a great way to stay on top of your health and take proactive measures to prevent any potential health issues.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    overlayTitle: "Jaanch - Std Profile Basic",
    overlayDetails: [
      "HEPATITIS PANEL (1)",
      "INFECTIOUS DISEASES (8)",
      "OTHERS (1)",
      "PREGNANCY (2)",
    ],
    avgRating: 4.5,
  },

  {
    id: "34",
    productName: "WOMEN HEALTH PACKAGE (71 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Thyroid Profile",
        tests: ["01", "02", "03"],
      },
      {
        categoryName: "Lipid Profile",
        tests: ["03", "04", "05", "07"],
      },
      // ... more categories can be added similarly
    ],
    imgUrl: womenhealth,
    category: "Basic",
    price: 1799.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "The WOMENS HEALTH PACKAGE consists of 71 types of blood tests, including Kidney, Liver, Thyroid, Iron, Lipid, Vitamin D, B12, Folic Acid, Hormones (important PCOD tests), CBC, and many more. This package is recommended for women aged between 16 to 50 years and also for those suffering from PCOD.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "35",
    productName: "MALE HEALTH PACKAGE (70 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Thyroid Profile",
        tests: ["01", "02", "03"],
      },
      {
        categoryName: "Lipid Profile",
        tests: ["03", "04", "05", "07"],
      },
      // ... more categories can be added similarly
    ],
    imgUrl: menhealth,
    category: "Basic",
    price: 1799.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "The FORTUNE BLOOD TEST MALE HEALTH PACKAGE is a comprehensive health checkup package consisting of 70 fundamental tests to screen for the overall health status of men aged from 25 to 45 years. It includes tests for Lipid, Liver, Thyroid, Iron, Diabetic, Kidney, Complete Hemogram, Hormone, Vitamin D & B12.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "36",
    productName: "GENERAL HEALTH PACKAGE (111)",
    type: "package",
    includedTests: [
      {
        categoryName: "Thyroid Profile",
        tests: ["01", "02", "03"],
      },
      {
        categoryName: "Lipid Profile",
        tests: ["03", "04", "05", "07"],
      },
      // ... more categories can be added similarly
    ],
    imgUrl: GenaralHealthPackage,
    category: "Basic",
    price: 2399.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "Introducing our General Health Package, meticulously crafted with 111 essential parameters for a comprehensive health screening. This package covers all aspects of general health, including Zinc, Copper, Magnesium, Folic Acid, Vitamin D and B12 levels, cholesterol, and other vital blood and urine tests. Ideal for individuals of any age group, it offers a holistic assessment to prioritize your well-being.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "37",
    productName: "FEMALE ADVANCED HEALTH PACKAGE (108 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Thyroid Profile",
        tests: ["01", "02", "03"],
      },
      {
        categoryName: "Lipid Profile",
        tests: ["03", "04", "05", "07"],
      },
      // ... more categories can be added similarly
    ],
    imgUrl: FemaleAdvancePackage,
    category: "Basic",
    price: 2499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "The FEMALE ADVANCED HEALTH PACKAGE includes 108 vital tests to screen the health status, such as Cardiac Risk Markers, Complete Hemogram, Diabetics, Iron, Lipid, Liver, Kidney, Thyroid, Vitamin, Pancreatic, Electrolytes, Ovarian Cancer, CEA (A Type of Tumour Marker) and Hormones. This package is recommended for women between the ages of 25 and 50 years old.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "38",
    productName: "MALE ADVANCED HEALTH PACKAGE (105 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Thyroid Profile",
        tests: ["01", "02", "03"],
      },
      {
        categoryName: "Lipid Profile",
        tests: ["03", "04", "05", "07"],
      },
      // ... more categories can be added similarly
    ],
    imgUrl: maleadvancepackage,
    category: "Basic",
    price: 2499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "The MALE ADVANCED HEALTH PACKAGE includes 105 vital tests to screen health status, such as Cardiac Risk Markers, Complete Hemogram, Diabetes, Iron, Lipid, Liver, Kidney, Thyroid, Vitamins, Pancreatic, Electrolytes, CEA (a type of Tumour Marker), Prostate Cancer (PSA), and Hormones. This package is recommended for men aged between 25 and 50 years.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "39",
    productName: "FEMALE SENIOR CITIZEN HEALTH PACKAGE (108 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Thyroid Profile",
        tests: ["01", "02", "03"],
      },
      {
        categoryName: "Lipid Profile",
        tests: ["03", "04", "05", "07"],
      },
      // ... more categories can be added similarly
    ],
    imgUrl: FemaleSeniorCitizen,
    category: "Basic",
    price: 3499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "The FEMALE SENIOR CITIZEN HEALTH PACKAGE consists of 108 fundamental tests for screening of health status, including Lipid, Liver, Kidney, Complete Hemogram, Diabetic screen, Iron, Thyroid, Electrolytes, Pancreas, Insulin, C-peptide, Homocysteine, Lp-PLA2 profiles, and CA 125 (Ovarian Cancer). This package is recommended for women aged above 45 years and for anyone suffering from high diabetes.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },

  {
    id: "40",
    productName: "MALE SENIOR CITIZEN HEALTH PACKAGE (108 Tests)",
    type: "package",
    includedTests: [
      {
        categoryName: "Thyroid Profile",
        tests: ["01", "02", "03"],
      },
      {
        categoryName: "Lipid Profile",
        tests: ["03", "04", "05", "07"],
      },
      // ... more categories can be added similarly
    ],
    imgUrl: seniorcitizen,
    category: "Basic",
    price: 3499.0,
    discount: 30,
    shortDesc: "Sample Type: Blood",
    description:
      "The MALE SENIOR CITIZEN HEALTH PACKAGE consists of 108 fundamental tests for screening of health status, including Lipid, Liver, Kidney, Complete Hemogram, Diabetic screen, Iron, Thyroid, Electrolytes, Pancreas, Insulin, C-peptide, Homocysteine, Lp-PLA2 profiles, and PSA (Prostate Cancer). This package is recommended for men aged above 45 years and for anyone suffering from high diabetes.",
    reviews: [
      {
        rating: 4.7,
        text: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
      },
    ],
    avgRating: 4.5,
  },
];
